@import "../../assets/sass/variables";

.public-layout {
  background-size: cover;
  background-position: center center;
  display: block;
  height: 100%;
  overflow: auto;
  width: 100%;

  .content-box {
    background: #fff;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    max-width: 420px;
    min-height: 100%;

    &.transparent {
      background: rgba(#fff, 0.9);
    }

    .content-header {
      padding-top: $module-rem * 2;
      margin: 15px 0px 0px 14%;
    }
    @media only screen and (max-width: 420px) {
      .content-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px;
        margin-top: 15%;
        margin-bottom: 15%;
        margin-right: 14%;
      }
      .right-align {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin: 0px auto;
      }
      .center-align {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
      }
      .width-100 {
        width: 100%;
      }
      .margin-bottom-small {
        margin-bottom: 20px;
      }
      .content-body {
        flex-grow: 0 !important;
      }
    }
    .content-body {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-grow: 1;
      padding: $module-rem ($module-rem * 4);
      width: 100%;

      > * {
        width: 100%;
      }
    }
  }
}
